import React, { useState } from "react";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { UseProducts } from "src/components/shop/UseProducts";

const SearchBar = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { products } = UseProducts();

  const [query, setQuery] = useState("");
  const handleSearch = (data: any) => {
    const search = data.map((item: any) => {
      item.attributes.filter((temp: any) =>
        temp.name.toLowerCase().includes(query)
      );
    });
    return search;
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: fullScreen ? "240px" : "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    margin: "16px",
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 26),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: fullScreen ? "black" : "whitesmoke",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: fullScreen ? "black" : "whitesmoke",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon sx={{ cursor: "pointer" }} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        // onChange={(e: any) => setQuery(e.target.value)}
      />
    </Search>
  );
};
export default SearchBar;
