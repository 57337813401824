import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

type CartItem = {
  name: string;
  price: number;
  colour: string;
  size: string;
  sizes: string[];
  image: any;
  description: string;
  productId: string;
  category: string;
  attributes: string[];
};
type attributes = {
  id: string;
  size: string;
  image: any;
  quantity: number;
  attributes: {
    name: string;
    sizes: string[];
    colour: string;
    price: number;
    description: string;
    quantity: {
      quantities: number;
    };
    category: {
      data: {
        id: string;
      };
    };
  };
};
const UseCart = (token: any) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [cart, setCart] = useState<CartItem[]>([]);
  const [updateCart, setUpdateCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const fetchCart = async () => {
      setIsLoading(true);
      try {
        const sessionId = localStorage.getItem("cartSessionId") || uuidv4();
        localStorage.setItem("cartSessionId", sessionId); // Store sessionID in localStorage
        const endpoint = token
          ? `${backendUrl}/api/carts`
          : `${backendUrl}/api/carts?sessionId=${sessionId}`; // Use token for logged-in, query param for non-logged-in

        const response = await axios.get(endpoint, {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }), // Add authorization header only for logged-in users
          },
        });

        setUpdateCart(false);
        setCart(response.data.data);
      } catch (error) {
        console.error({ error });
      } finally {
        setIsLoading(false);
      }
    };

    fetchCart();
  }, [backendUrl, token, updateCart]);

  useEffect(() => {
    if (cart && Array.isArray(cart)) {
      setTotalPrice(
        cart.reduce(
          (acc: any, value: any) =>
            acc + Number(value.price) * Number(value.quantity),
          0
        )
      );
    } else {
      setTotalPrice(0); // Reset totalPrice if cart is empty
    }
  }, [cart]);
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);
  const addToCart = async ({
    id,
    size,
    image,
    quantity,
    attributes: {
      name,
      sizes,
      price,
      colour,
      category,
      description,
      quantity: quantities,
    },
  }: attributes) => {
    const isSameProductExistinCart = cart.filter(
      (product) =>
        Number(product.productId) === Number(id) &&
        product.size === size &&
        product.colour === colour
    );
    if (!isSameProductExistinCart.length) {
      try {
        const sessionId = localStorage.getItem("cartSessionId") || uuidv4();
        localStorage.setItem("cartSessionId", sessionId);
        const cartData = {
          name,
          price,
          colour,
          size,
          sizes,
          image,
          quantities,
          description,
          productId: id,
          quantity: Number(quantity),
          categoryId: category.data.id,
          sessionID: sessionId,
        };
        if (token) {
          await axios.post(
            `${backendUrl}/api/carts`,
            { data: cartData },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUpdateCart(true);
        } else {
          await axios.post(
            `${backendUrl}/api/carts`,
            { data: cartData },
            {
              headers: {
                sessionId: `${sessionId}`,
              },
            }
          );
        }
        // Store cart data locally for non-logged-in users
        if (!token) {
          setCart((cart: any) => [...cart, cartData]);
          localStorage.setItem("cart", JSON.stringify(cart));
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Same product added to the basket already!", {
        position: toast.POSITION.TOP_LEFT,
        hideProgressBar: true,
      });
    }
  };

  const updateCartItem = async ({
    colour,
    size,
    index,
    image,
    quantity,
    productId,
    cartItemId,
  }: any) => {
    try {
      if (token) {
        await axios.put(`${backendUrl}/api/carts/${cartItemId}`, {
          data: {
            colour,
            size,
            image,
            quantity: Number(quantity),
          },
          headers: {
            Authorization: `bearer ${token}`,
          },
        });
        setUpdateCart(true);
      } else {
        const updatedCart = cart.map((item: any, idx: any) => {
          if (index === idx && productId === item.productId) {
            return {
              ...item,
              colour,
              size,
              image,
              quantity: Number(quantity),
            };
          }
          return item;
        });
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const removeFromCart = async ({ index, productId, cartItemId }: any) => {
    try {
      if (!!token) {
        await axios.delete(`${backendUrl}/api/carts/${cartItemId}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        });
        setUpdateCart(true);
      } else {
        const cartAfterRemovedItem = cart.filter(
          (item: any, idx: any) => item.productId !== productId || index !== idx
        );
        setCart(cartAfterRemovedItem);
        localStorage.setItem("cart", JSON.stringify(cartAfterRemovedItem));
      }
    } catch (error) {}
  };
  return {
    cart,
    addToCart,
    removeFromCart,
    updateCartItem,
    isLoading,
    totalPrice,
  };
};

export default UseCart;
