import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { v4 as uuidv4 } from "uuid";

type Orders = {
  id: number;
  attributes: {
    firstname: string;
    lastname: string;
    userEmail: string;
    phone: string;
    address: string;
    total: string;
    country: string;
    city: string;
    postal_code: string;
    code: string;
    status: string;
    sessionID: string;
    orders: string[];
  };
};
const UseCheckout = (token: any) => {
  const [orders, setOrders] = useState<Orders[]>([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  // ===========================EmailJS================================
  const serviceID = "service_m7skghr";
  const templateID = "template_ovomemr";
  const UserID = "3RW-fvPGp-PYv1FtH";
  const sendOrderConfirmationEmail = async (orderData: any) => {
    try {
      const templateParams = {
        nav_bar: "nirnishop",
        user_name:
          orderData.attributes.firstname + " " + orderData.attributes.lastname,
        user_email: orderData.attributes.userEmail,
        country: orderData.attributes.country,
        message: orderData.map((item: any) => ({
          name: item.name,
          image: item.image,
          size: item.size,
          price: item.price,
          quantity: item.quantity,
          colour: item.colour,
          createdAt: item.createdAt,
        })),
        orderId: orderData.id,
        total: orderData.attributes.total + ".00$",
      };

      await emailjs.send(serviceID, templateID, templateParams, UserID);
      console.log("Email sent successfully!");
    } catch (error) {
      throw error; // Rethrow for handling in the `onApprove` function
    }
  };
  useEffect(() => {
    const getOrderData = async () => {
      try {
        if (!!token) {
          await axios
            .get(`${backendUrl}/api/orders`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(({ data }: any) => {
              setOrders(data.data);
              console.log(data.data);
            });
        }
      } catch (error) {
        console.log({ error });
      }
    };
    if (!!token) {
      getOrderData();
    }
  }, [backendUrl, token]);

  const addToOrder = async ({
    id,
    attributes: {
      firstname,
      lastname,
      userEmail,
      phone,
      address,
      total,
      country,
      city,
      postal_code,
      code,
      status,
      orders,
      sessionID,
    },
  }: Orders) => {
    try {
      const orderData = {
        firstname,
        lastname,
        userEmail,
        phone,
        address,
        total,
        country,
        city,
        postal_code,
        code,
        status,
        orders,
        sessionID,
      };
      if (!!token) {
        const response = await axios.post(
          `${backendUrl}/api/orders`,
          { data: orderData },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        sendOrderConfirmationEmail(orderData);
        return response;
      } else {
        setOrders((orders: any) => [...orders, orderData]);
        sendOrderConfirmationEmail(orderData);
        console.log("check orders", orders);
      }
      toast.success("Order placed! You will receive an email confirmation", {
        position: toast.POSITION.TOP_LEFT,
        hideProgressBar: true,
      });
    } catch (error) {
      toast.error("Order failed!", {
        position: toast.POSITION.TOP_LEFT,
        hideProgressBar: true,
      });
    }
  };
  return {
    orders,
    addToOrder,
  };
};

export default UseCheckout;
