import React, { useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface Image {
  attributes: {
    url: string;
  };
}

const ProductCard = ({ product }: any) => {
  const [hoveredImage, setHoveredImage] = useState<Image | null>(null);

  const isOutOfStock = () => {
    if (product !== null) {
      const { attributes }: any = product;
      if (attributes && attributes.quantity?.[0] === "0") {
        return true;
      }
      return false;
    }
  };
  const image = product.attributes.images.data[0].attributes;
  const imageHover = product.attributes.images.data[1].attributes;
  const products = {
    attributes: {
      images: {
        data: [
          { attributes: { url: { image }, name: "Image 1" } },
          { attributes: { url: { imageHover }, name: "Image 2" } },
        ],
      },
    },
  };
  const handleMouseEnter = (image: any) => {
    setHoveredImage(image);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  return (
    <Card
      className="card_list"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        borderRadius: "2px",
      }}
    >
      <LazyLoadImage
        className="card_media"
        effect="blur"
        src={
          hoveredImage
            ? hoveredImage.attributes.url
            : product.attributes.images.data[0].attributes.url
        }
        alt={image.name}
        onMouseEnter={() => handleMouseEnter(product.attributes.images.data[1])} // Enter function with second image data
        onMouseLeave={handleMouseLeave}
      />
      <CardContent className="card_content" sx={{ flexGrow: 1 }}>
        <Typography className="card_content title" variant="subtitle2">
          {product.attributes.name}
        </Typography>
        {isOutOfStock() === true ? (
          <Typography
            color={"darkgray"}
            className="card_content price"
            variant="body1"
          >
            OUT OF STOCK
          </Typography>
        ) : (
          <Typography className="card_content price" variant="subtitle2">
            ${product.attributes.price}.00
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ProductCard;
