import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const LoginRedirect = () => {
  const [text, setText] = useState("Loading...");
  const location = useLocation();
  const params = useParams<{ providerName?: string }>();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios
      .get(`${backendUrl}/api/auth/google/callback${location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res.data;
      })
      .then((data) => {
        console.log(data.user);

        localStorage.setItem(
          "user",
          JSON.stringify({
            username: data.user.username,
            email: data.user.email,
            jwt: data.jwt,
          })
        );
        setText(
          "You have been successfully logged in. You will be redirected in a few seconds..."
        );
        navigate("/shop");
        window.location.reload();
        return data;
      })
      .catch((err) => {
        console.error(err);
        setText("An error occurred, please see the developer console.");
      });
  }, [backendUrl, navigate, location.search, params.providerName]);

  return <p>{text}</p>;
};

export default LoginRedirect;
