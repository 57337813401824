import { useState, useEffect } from "react";
import axios from "axios";

export const UseProducts = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const fetchCategories = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${backendUrl}/api/categories?populate=*`);
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchProducts = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${backendUrl}/api/products?populate=*`);
      setProducts(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchProducts();
  }, []);

  return {
    products,
    categories,
  };
};
